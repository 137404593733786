/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { useContext } from 'react'
import { ConfigContext, ConfigContextProps } from './ConfigContext'

export const useConfig = (): ConfigContextProps => {
  const context = useContext<ConfigContextProps | undefined>(ConfigContext)

  if (context == null) {
    throw new Error('ConfigProvider context is undefined, please verify you are calling useConfig() as child of a <ConfigProvider> component.')
  }

  return context
}
