import RemovalRequest from '../models/RemovalRequest'
import { RemovalRequestPayload } from '../models/RemovalRequestPayload'
import { StatusType } from '../models/Status'

export interface RemovalRequestAPI {
  status: StatusType
}

export const fetchRemovalRequests = async (url: string, token: string | undefined) => {
  if (token == null) return

  try {
    const res = await fetch(`${url}/admin/removalRequests`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (r) => await r.json() as RemovalRequestPayload[])

    return res.map((rr: RemovalRequestPayload) => {
      return new RemovalRequest(rr)
    })
  } catch (err) {
    console.error('Error while fetching removal requests:', err)
  }
}

export const sendUpdateRemovalRequest = async (url: string, removalRequestId: string, removalRequestPyload: RemovalRequestAPI | null, token?: string) => {
  console.log('sendUpdateRemovalRequest()', removalRequestId, removalRequestPyload)

  if ((removalRequestId == null) || (token == null)) return

  try {
    await fetch(`${url}/admin/removalRequests/${removalRequestId}`, {
      method: 'PATCH',
      body: JSON.stringify(removalRequestPyload),
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
  } catch (err) {
    console.log('Error when sending update removal requests:', err)
  }
}

export default fetchRemovalRequests
