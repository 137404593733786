import React from 'react'

import RemovalRequestRow from './RemovalRequestRow'
import RemovalRequest from '../models/RemovalRequest'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Accordion from 'react-bootstrap/Accordion'
import Stack from 'react-bootstrap/Stack'
import Badge from 'react-bootstrap/Badge'

interface RemovalRequestContainerProps {
  title: string
  description: string
  removalRequests: RemovalRequest[]
  openConfirmHandledModal: (removalRequestId: RemovalRequest) => void
  openRemovalRequestDetailsModal: (removalRequestId: string) => void
  activeRemovalRequestId: string | null
  showMarkHandledButton: boolean
  markHandledButtonText: string
}

const RemovalRequestContainer: React.FC<RemovalRequestContainerProps> = (
  {
    title,
    description,
    removalRequests,
    openConfirmHandledModal,
    openRemovalRequestDetailsModal,
    activeRemovalRequestId,
    showMarkHandledButton,
    markHandledButtonText
  }
) => {
  return (
    <>
      <Accordion.Header>
        <Stack direction="horizontal" gap={1}>
          {title}
          <Badge pill bg="dark">{removalRequests.length}</Badge>
        </Stack>
      </Accordion.Header>
      <Accordion.Body>
        <div className="removal-request-container">
          <Card>
            <Card.Body>
              <Card.Text>{description}</Card.Text>
            </Card.Body>
            <ListGroup variant="flush">
              {
                removalRequests.map((rr, i) => {
                  return (
                    <ListGroup.Item
                      active={rr.id === activeRemovalRequestId}
                      key={`rrc-item-${i}`}>
                      <RemovalRequestRow
                        removalRequest={rr}
                        openConfirmHandledModal={openConfirmHandledModal}
                        openRemovalRequestDetailsModal={openRemovalRequestDetailsModal}
                        showMarkHandledButton={showMarkHandledButton}
                        markHandledButtonText={markHandledButtonText}
                      />
                    </ListGroup.Item>
                  )
                })
              }

            </ListGroup>
          </Card>
        </div>
      </Accordion.Body>
    </>
  )
}

export default RemovalRequestContainer
