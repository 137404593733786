import React from 'react'
import RemovalRequest from '../../models/RemovalRequest'
import ActionModal from './ActionModal'

interface ConfirmToBeClosedModalProps {
  open: boolean
  onClose: () => void
  activeRemovalRequest?: RemovalRequest
  onAction: () => void
}

const ConfirmToBeClosedModal: React.FC<ConfirmToBeClosedModalProps> = (
  {
    open,
    onClose,
    activeRemovalRequest,
    onAction
  }
) => {
  return <ActionModal
    title="Markera ärendet som avslutat"
    description="Genom att klicka på Markera avslutad bekräftar du att patientkontot samt tillhörande personuppgifter har raderats."
    closeButtonText="Avbryt"
    ctaButtonText="Markera avslutat"
    open={open}
    onClose={onClose}
    activeRemovalRequest={activeRemovalRequest}
    onAction={onAction}
   />
}

export default ConfirmToBeClosedModal
