import React from 'react'

import { useAuth } from 'oidc-react'
import { Col, Container, Row } from 'react-bootstrap'
import RemovalRequestPage from './RemovalRequestPage'

const LoggedInPage: React.FC = () => {
  const auth = useAuth()

  if (auth.userData != null) {
    return (
      <RemovalRequestPage userData={auth.userData} />
    )
  } else {
    return (
      <Container>
        <Row>
          <Col>
            Ej inloggad.
          </Col>
        </Row>
      </Container>
    )
  }
}

export default LoggedInPage
