import Patient from './Patient'
import { RemovalRequestPayload } from './RemovalRequestPayload'
import Status, { StatusType } from './Status'
import StatusPayload from './StatusPayload'

export default class RemovalRequest {
  id!: string
  createdAt!: Date
  closedAt: Date | null
  patient: Patient | null
  statusHistory: Status[]

  constructor(json: RemovalRequestPayload) {
    this.id = json.id
    this.createdAt = new Date(String(json.createdAt))
    this.closedAt = (json.closedAt != null) ? new Date(String(json.closedAt)) : null
    this.patient = (json.patient != null) ? new Patient(json.patient) : null
    this.statusHistory = this.sortStatusHistory(this.convertStatusPayloadToStatus(json.statusHistory))
  }

  getCurrentStatus(): Status | null {
    return this.statusHistory[0] ?? null
  }

  private sortStatusHistory(statusHistory: Status[]): Status[] {
    return statusHistory.sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime()
    })
  }

  private convertStatusPayloadToStatus(statusPayload: StatusPayload[]): Status[] {
    if (statusPayload === null || statusPayload === undefined) {
      console.log('StatusPayload was null or undefined')
      return []
    }

    return statusPayload.map(s => new Status(s.type, new Date(s.createdAt)))
  }
}

export const isNotStarted = (r: RemovalRequest) => r.getCurrentStatus()?.type === StatusType.NOT_STARTED
export const isInProgress = (r: RemovalRequest) => r.getCurrentStatus()?.type === StatusType.IN_PROGRESS
export const isToBeClosed = (r: RemovalRequest) => r.getCurrentStatus()?.type === StatusType.TO_BE_CLOSED
export const isClosed = (r: RemovalRequest) => r.closedAt != null
