import React from 'react'

import RemovalRequest, { isToBeClosed, isClosed, isInProgress } from '../models/RemovalRequest'

import Stack from 'react-bootstrap/Stack'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Moment from 'react-moment'
import 'moment/locale/sv'

interface RemovalRequestRowProps {
  removalRequest: RemovalRequest
  openConfirmHandledModal: (removalRequestId: RemovalRequest) => void
  openRemovalRequestDetailsModal: (removalRequestId: string) => void
  showMarkHandledButton: boolean
  markHandledButtonText: string
}

const RemovalRequestRow: React.FC<RemovalRequestRowProps> = (
  {
    removalRequest,
    openConfirmHandledModal,
    openRemovalRequestDetailsModal,
    showMarkHandledButton,
    markHandledButtonText
  }
) => {
  const dateSpans = [<Badge key="0" bg="light" text="dark">Skapad <Moment locale="sv" fromNow>{removalRequest.createdAt}</Moment></Badge>]

  if (isClosed(removalRequest)) {
    dateSpans.push(<Badge key="1" bg="danger">Stängd <Moment locale="sv"fromNow>{removalRequest.closedAt ?? ''}</Moment></Badge>)
  } else if (isInProgress(removalRequest)) {
    dateSpans.push(<Badge key="2" bg="primary">Hanterad för <Moment locale="sv" fromNow>{removalRequest.getCurrentStatus()?.createdAt ?? ''}</Moment></Badge>)
  } else if (isToBeClosed(removalRequest)) {
    dateSpans.push(<Badge key="3" bg="primary">Hanterad för <Moment locale="sv" fromNow>{removalRequest.getCurrentStatus()?.createdAt ?? ''}</Moment></Badge>)
  }

  return (
    <div className="removal-request-row">
      <Stack direction="horizontal" gap={2}>
        <Badge bg="light" text="dark">{removalRequest.id}</Badge>
        {(removalRequest.patient?.fullName) ?? '[Patient raderad]'}
        {dateSpans}

        <div className="ms-auto"><Button onClick={(() => { openRemovalRequestDetailsModal(removalRequest.id) })} variant="light" size="sm">Information</Button></div>
        {showMarkHandledButton &&
          <div className="">
            <Button
              onClick={(() => { openConfirmHandledModal(removalRequest) })}
              variant="primary"
              size="sm">
                {markHandledButtonText}
            </Button>
          </div>
        }
      </Stack>
    </div>
  )
}

export default RemovalRequestRow
