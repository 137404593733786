import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import RemovalRequest from '../models/RemovalRequest'
import { StatusType } from '../models/Status'

interface RemovalRequestDetailsModalProps {
  open: boolean
  onClose: () => void
  activeRemovalRequest: RemovalRequest | null
}

const RemovalRequestDetailsModal: React.FC<RemovalRequestDetailsModalProps> = (
  {
    open,
    onClose,
    activeRemovalRequest

  }
) => {
  if (activeRemovalRequest === null) {
    return <></>
  }

  const { id, createdAt, statusHistory, closedAt, patient } = activeRemovalRequest

  const handledByPTJAt = statusHistory.find(s => s.type === StatusType.IN_PROGRESS)?.createdAt
  const userRemovedAt = statusHistory.find(s => s.type === StatusType.TO_BE_CLOSED)?.createdAt

  const requestTable = (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan={2}>Ärende</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ärendenummer</td>
          <td>{id}</td>
        </tr>
        <tr>
          <td>Skapat</td>
          <td>{createdAt.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Åtgärdat av Praktikertjänst</td>
          <td>{handledByPTJAt?.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Användare raderad</td>
          <td>{userRemovedAt?.toLocaleString()}</td>
        </tr>
        <tr>
          <td>Avslutat</td>
          <td>{closedAt?.toLocaleString()}</td>
        </tr>
      </tbody>
    </Table>
  )

  const patientTable = (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan={2}>Användare</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Namn</td>
          <td>{patient?.fullName}</td>
        </tr>
        <tr>
          <td>ID</td>
          <td>{patient?.doctrinId}</td>
        </tr>
        <tr>
          <td>Telefonnummer</td>
          <td>{patient?.phoneNumber}</td>
        </tr>
      </tbody>
    </Table>
  )
  return (
    <Modal size="lg" centered show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {requestTable}

        {(patient != null) ? patientTable : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Avbryt
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemovalRequestDetailsModal
