
export enum StatusType {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  TO_BE_CLOSED = 'TO_BE_CLOSED',
  CLOSED = 'CLOSED',
  UNKNOWN = 'UNKNOWN'
}

export default class Status {
  type!: StatusType
  createdAt!: Date

  constructor(type: string, createdAt: Date) {
    this.type = this.convertToStatusType(type)
    this.createdAt = createdAt
  }

  private convertToStatusType(status: string): StatusType {
    switch (status) {
      case 'NOT_STARTED':
        return StatusType.NOT_STARTED
      case 'IN_PROGRESS':
        return StatusType.IN_PROGRESS
      case 'TO_BE_CLOSED':
        return StatusType.TO_BE_CLOSED
      case 'CLOSED':
        return StatusType.CLOSED
      default:
        console.log('Unknown status type:', status)
        return StatusType.UNKNOWN
    }
  }
}
