import React from 'react'
import RemovalRequest from '../../models/RemovalRequest'
import ActionModal from './ActionModal'

interface ConfirmStartedModalProps {
  open: boolean
  onClose: () => void
  activeRemovalRequest?: RemovalRequest
  onAction: (removalRequest?: RemovalRequest) => void
}

const ConfirmStartedModal: React.FC<ConfirmStartedModalProps> = (
  {
    open,
    onClose,
    activeRemovalRequest,
    onAction
  }
) => {
  return <ActionModal
    title="Markera ärendet som påbörjat"
    description="Genom att klicka på Markera påbörjad flyttas ärendet till Ärenden att åtgärda"
    closeButtonText="Avbryt"
    ctaButtonText="Markera påbörjad"
    open={open}
    onClose={onClose}
    activeRemovalRequest={activeRemovalRequest}
    onAction={onAction}
   />
}

export default ConfirmStartedModal
