
import RemovalRequestContainer from './RemovalRequestContainer'
import RemovalRequestDetailsModal from './RemovalRequestDetailsModal'
import RemovalRequest, { isClosed, isToBeClosed, isInProgress, isNotStarted } from '../models/RemovalRequest'
import Accordion from 'react-bootstrap/Accordion'
import { Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { User } from 'oidc-react'
import { useConfig } from './useConfig'
import fetchRemovalRequests, { RemovalRequestAPI, sendUpdateRemovalRequest } from '../api/removalRequests'
import ConfirmStartedModal from './actionModals/ConfirmStartedModal'
import ConfirmHandledModal from './actionModals/ConfirmHandledModal'
import ConfirmToBeClosedModal from './actionModals/ConfirmToBeClosedModal'
import { StatusType } from '../models/Status'

function filterNotStarted(removalRequests: RemovalRequest[]) {
  return removalRequests.filter(isNotStarted)
}

function filterInProgress(removalRequests: RemovalRequest[]) {
  return removalRequests.filter(isInProgress)
}

function filterToBeClosed(removalRequests: RemovalRequest[]) {
  return removalRequests.filter(isToBeClosed)
}

function filterClosed(removalRequests: RemovalRequest[]) {
  return removalRequests.filter(isClosed)
}

interface RemovalRequestPageProps {
  userData: User
}

const RemovalRequestPage: React.FC<RemovalRequestPageProps> = ({ userData }) => {
  const config = useConfig()
  const [removalRequests, setRemovalRequests] = useState<RemovalRequest[]>([])
  const [activeRemovalRequestId, setActiveRemovalRequestId] = useState<null | string>(null)
  const [removalRequestDetailsModalOpen, setRemovalRequestDetailsModalOpen] = useState(false)
  const [removalRequestToMarkAsStarted, setRemovalRequestToMarkAsStarted] = useState<RemovalRequest | null>(null)
  const [removalRequestToMarkAsHandled, setRemovalRequestToMarkAsHandled] = useState<RemovalRequest | null>(null)
  const [removalRequestToMarkAsToBeClosed, setRemovalRequestToMarkAsToBeClosed] = useState<RemovalRequest | null>(null)

  useEffect(() => {
    fetchRemovalRequests(config.apiBaseUrl, userData.access_token).then((rr) => {
      (rr != null) && setRemovalRequests(rr)
    }).catch(e => { console.error(e) })
  }, [])

  const closeRemovalRequestDetailsModal = () => {
    setActiveRemovalRequestId(null)
    setRemovalRequestDetailsModalOpen(false)
  }
  const openRemovalRequestDetailsModal = (removalRequestId: string) => {
    setActiveRemovalRequestId(removalRequestId)
    setRemovalRequestDetailsModalOpen(true)
  }

  const handleUpdateRemovalRequest = async (payload: RemovalRequestAPI, callback: () => void, removalRequestId?: string) => {
    if (removalRequestId == null) return

    await sendUpdateRemovalRequest(config.apiBaseUrl, removalRequestId, payload, userData.access_token)
    const updatedRemovalRequests = await fetchRemovalRequests(config.apiBaseUrl, userData.access_token)
    setRemovalRequests(updatedRemovalRequests ?? [])

    callback()
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
              <Accordion.Item eventKey='0'>
                <RemovalRequestContainer
                  title='Nya ärenden'
                  description='Ärenden att åtgärda. När ärendet är åtgärdat ska patientkontot samt tillhörande personuppgifter raderas och ärendet markeras som åtgärdat.'
                  removalRequests={filterNotStarted(removalRequests)}
                  activeRemovalRequestId={activeRemovalRequestId}
                  openConfirmHandledModal={(removalRequest: RemovalRequest) => { setRemovalRequestToMarkAsStarted(removalRequest) }}
                  openRemovalRequestDetailsModal={
                    openRemovalRequestDetailsModal
                  }
                  showMarkHandledButton={true}
                  markHandledButtonText='Påbörjad'
                />
              </Accordion.Item>

              <Accordion.Item eventKey='1'>
                <RemovalRequestContainer
                  title='Ärenden att åtgärda'
                  description='Åtgärdade ärenden. När ärendet är åtgärdat ska patientkontot samt tillhörande personuppgifter raderas och ärendet markeras som åtgärdat'
                  removalRequests={filterInProgress(removalRequests)}
                  activeRemovalRequestId={activeRemovalRequestId}
                  openConfirmHandledModal={(removalRequest: RemovalRequest) => { setRemovalRequestToMarkAsHandled(removalRequest) }}
                  openRemovalRequestDetailsModal={
                    openRemovalRequestDetailsModal
                  }
                  showMarkHandledButton={true}
                  markHandledButtonText="Åtgärdad"
                />
              </Accordion.Item>

              <Accordion.Item eventKey='2'>
                <RemovalRequestContainer
                  title='Ärenden att avsluta'
                  description='Ärenden att avsluta. Patientkontot samt tillhörande personuppgifter  har raderats.  Genom  att avsluta ärendet anonymiseras  till ärendet tillhörande personuppgifter.'
                  removalRequests={filterToBeClosed(removalRequests)}
                  activeRemovalRequestId={activeRemovalRequestId}
                  openConfirmHandledModal={(removalRequest: RemovalRequest) => { setRemovalRequestToMarkAsToBeClosed(removalRequest) }}
                  openRemovalRequestDetailsModal={
                    openRemovalRequestDetailsModal
                  }
                  showMarkHandledButton={true}
                  markHandledButtonText="Avsluta ärendet"
                />
              </Accordion.Item>

              <Accordion.Item eventKey='3'>
                <RemovalRequestContainer
                  title='Avslutade ärenden'
                  description='Avslutade ärenden. Patientkontot samt tillhörande personuppgifter har raderats.'
                  removalRequests={filterClosed(removalRequests)}
                  activeRemovalRequestId={activeRemovalRequestId}
                  openConfirmHandledModal={(removalRequest: RemovalRequest) => { console.log('will never be called,' + removalRequest.id) }}
                  openRemovalRequestDetailsModal={
                    openRemovalRequestDetailsModal
                  }
                  showMarkHandledButton={false}
                  markHandledButtonText=""
                />
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      <ConfirmStartedModal
        open={removalRequestToMarkAsStarted !== null}
        activeRemovalRequest={removalRequestToMarkAsStarted ?? undefined}
        onClose={() => { setRemovalRequestToMarkAsStarted(null) }}
        onAction={async (removalRequest?: RemovalRequest) => {
          await handleUpdateRemovalRequest(
            { status: StatusType.IN_PROGRESS },
            () => { setRemovalRequestToMarkAsStarted(null) },
            removalRequest?.id
          )
        }}
      />

      <ConfirmHandledModal
        open={removalRequestToMarkAsHandled !== null}
        activeRemovalRequest={removalRequestToMarkAsHandled ?? undefined}
        onClose={() => { setRemovalRequestToMarkAsHandled(null) }}
        onAction={async (removalRequest?: RemovalRequest) => {
          await handleUpdateRemovalRequest(
            { status: StatusType.TO_BE_CLOSED },
            () => { setRemovalRequestToMarkAsHandled(null) },
            removalRequest?.id
          )
        }}
      />

      <ConfirmToBeClosedModal
        open={removalRequestToMarkAsToBeClosed !== null}
        activeRemovalRequest={removalRequestToMarkAsToBeClosed ?? undefined}
        onClose={() => { setRemovalRequestToMarkAsToBeClosed(null) }}
        onAction={async (removalRequest?: RemovalRequest) => {
          await handleUpdateRemovalRequest(
            { status: StatusType.CLOSED },
            () => { setRemovalRequestToMarkAsToBeClosed(null) },
            removalRequest?.id
          )
        }}
      />

      <RemovalRequestDetailsModal
        open={removalRequestDetailsModalOpen}
        activeRemovalRequest={
          removalRequests.find((r) => r.id === activeRemovalRequestId) ?? null
        }
        onClose={closeRemovalRequestDetailsModal}
      />
    </>
  )
}

export default RemovalRequestPage
