export default class Patient {
  fullName!: string
  doctrinId!: string
  phoneNumber?: string

  constructor(json: { fullName: string, doctrinId: string, phoneNumber?: string }) {
    this.fullName = json.fullName
    this.doctrinId = json.doctrinId
    this.phoneNumber = json.phoneNumber
  }
}
