import './App.css'

import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import NavbarHeader from './components/NavbarHeader'
import LoggedInPage from './components/LoggedInPage'
import ConfigProvider from './components/ConfigProvider'
import AuthWrapper from './components/AuthWrapper'

function App() {
  return (
    <ConfigProvider>
      <AuthWrapper>
        <NavbarHeader />
        <LoggedInPage />
      </AuthWrapper>
    </ConfigProvider>
  )
}

export default App
