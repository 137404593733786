
import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import RemovalRequest from '../../models/RemovalRequest'

interface ActionModalProps {
  title: string
  description: string
  closeButtonText: string
  ctaButtonText: string
  open: boolean
  activeRemovalRequest?: RemovalRequest
  onClose: () => void
  onAction: (removalRequest?: RemovalRequest) => void
}

const ActionModal: React.FC<ActionModalProps> = (
  {
    title,
    description,
    closeButtonText = 'Avbryt',
    ctaButtonText,
    open,
    activeRemovalRequest,
    onClose,
    onAction
  }
) => {
  if (activeRemovalRequest === null) {
    return <></>
  }

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
         {closeButtonText}
        </Button>
        <Button variant="primary" onClick={() => { onAction(activeRemovalRequest) }}>
         {ctaButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActionModal
