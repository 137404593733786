import React, { FC, PropsWithChildren, useState } from 'react'
import { ConfigContext, ConfigContextProps } from './ConfigContext'

export interface ConfigProviderProps {
  temp?: string
}

async function fetchConfig(): Promise<ConfigContextProps> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
  const res: ConfigContextProps = await fetch('/config').then(async res => await res.json())
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return res
}

export const ConfigProvider: FC<PropsWithChildren<ConfigProviderProps>> = ({
  children
}) => {
  const [configData, setConfigData] = useState<ConfigContextProps>({
    loaded: false,
    apiBaseUrl: '',
    clientId: '',
    clientSecret: '',
    keycloakUrl: ''
  })

  if (!configData.loaded) {
    fetchConfig().then(config => {
      setConfigData({
        loaded: true,
        apiBaseUrl: config.apiBaseUrl,
        keycloakUrl: config.keycloakUrl,
        clientId: config.clientId,
        clientSecret: config.clientSecret
      })
    }).catch(err => {
      console.log('Could not fetch config', err)
    })
  }
  return <ConfigContext.Provider value={configData}>
    {children}
  </ConfigContext.Provider>
}

export default ConfigProvider
