import React from 'react'
import RemovalRequest from '../../models/RemovalRequest'
import ActionModal from './ActionModal'

interface ConfirmHandledModalProps {
  open: boolean
  onClose: () => void
  activeRemovalRequest?: RemovalRequest
  onAction: () => void
}

const ConfirmHandledModal: React.FC<ConfirmHandledModalProps> = (
  {
    open,
    onClose,
    activeRemovalRequest,
    onAction
  }
) => {
  return <ActionModal
    title="Markera ärendet som åtgärdat"
    description="Genom att klicka på Markera åtgärdad bekräftar du att patientkontot  samt tillhörande  personuppgifter  har raderats"
    closeButtonText="Avbryt"
    ctaButtonText="Markera åtgärdad"
    open={open}
    onClose={onClose}
    activeRemovalRequest={activeRemovalRequest}
    onAction={onAction}
   />
}

export default ConfirmHandledModal
