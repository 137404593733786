import { AuthProvider } from 'oidc-react'
import React, { PropsWithChildren } from 'react'
import { useConfig } from './useConfig'

function AuthWrapper(props: PropsWithChildren<unknown>) {
  const config = useConfig()

  const oidcConfig = {
    onSignIn: () => {
      window.location.replace(window.location.origin)
    },
    postLogoutRedirectUri: window.location.origin,
    location: window.location,
    authority: config.keycloakUrl,
    clientId: config.clientId,
    clientSecret: config.clientSecret,
    redirectUri: window.location.origin,
    responseType: 'code'
  }

  if (!config.loaded) {
    return <div>Loading config...</div>
  }
  return <AuthProvider {...oidcConfig}>
      {props.children}
  </AuthProvider>
}

export default AuthWrapper
