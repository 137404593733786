import React from 'react'
import { Button } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { useAuth } from 'oidc-react'
import logo from '../praktikertjanst-bla-logotyp.svg'

function NavbarHeader() {
  const auth = useAuth()

  if (auth.userData != null) {
    return (
      <Navbar style={{ marginBottom: '1rem' }} bg="light" variant="light" className="ptj-navbar">
        <Container>
          <Navbar.Brand>
            <img src={logo} className="app-logo" alt="Praktikertjänst logo" />
          </Navbar.Brand>
          <Button onClick={() => { void auth.signOutRedirect() }} variant="light">Logga ut</Button>
        </Container>
      </Navbar>
    )
  } else {
    return (
      <Navbar style={{ marginBottom: '1rem' }} bg="light" variant="light" className="ptj-navbar">
        <Container>
          <Navbar.Brand>
            <img src={logo} className="app-logo" alt="Praktikertjänst logo" />
          </Navbar.Brand>
          <Button onClick={() => { void auth.signIn() }} variant="light">Logga in</Button>
        </Container>
      </Navbar>
    )
  }
}

export default NavbarHeader
