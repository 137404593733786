import React from 'react'

export interface ConfigContextProps {
  loaded: boolean
  apiBaseUrl: string
  keycloakUrl: string
  clientId: string
  clientSecret: string
}

export const ConfigContext = React.createContext<ConfigContextProps | undefined>({
  loaded: false,
  apiBaseUrl: '',
  keycloakUrl: '',
  clientId: '',
  clientSecret: ''
})
